import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { ItalicText } from '../../components/italic-text/italic-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Have you ever had to retrieve information from multiple data sources or different API endpoints? Did you optimize
your request sequence for `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Concurrency_(computer_science)"
      }}>{`concurrency`}</a>{` and
interdependent data?`}</p>
    <p>{`If you answered yes to at least one of these questions, you should read on now. I'll show you my solution using
the `}<a parentName="p" {...{
        "href": "https://angular.io/"
      }}>{`Angular framework`}</a>{` and `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/rxjs"
      }}>{`RxJS`}</a>{`. Let your requests flow
like a series of interconnected waterfalls.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHnMAlaa//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMhERL/2gAIAQEAAQUCrX01ilZkRysW7WA7/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAABFB/9oACAECAQE/AcR//8QAHBAAAgEFAQAAAAAAAAAAAAAAAAEhAhAREjFR/9oACAEBAAY/AqX4apxm0HEcP//EABsQAQADAQADAAAAAAAAAAAAAAEAESExQWGR/9oACAEBAAE/IW4ouwLTY1Eb5+wD2Ijrbblnln//2gAMAwEAAgADAAAAEBTv/8QAFxEAAwEAAAAAAAAAAAAAAAAAARAhMf/aAAgBAwEBPxAbV//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIXH/2gAIAQIBAT8QQMXV/8QAHBABAQACAwEBAAAAAAAAAAAAAREAITFBgWFx/9oACAEBAAE/ELaQbPG+PcSJ1ih+J5jhSu9OcQqgWtQnnP3GipVK3x6xl4GVW5//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "A series of waterfalls flowing down a rocky slope.",
          "title": "A series of waterfalls flowing down a rocky slope.",
          "src": "/static/4a9eb7beeb95b7b1ead10bd5652aff71/e5166/pexels-quang-nguyen-vinh-waterfalls.jpg",
          "srcSet": ["/static/4a9eb7beeb95b7b1ead10bd5652aff71/f93b5/pexels-quang-nguyen-vinh-waterfalls.jpg 300w", "/static/4a9eb7beeb95b7b1ead10bd5652aff71/b4294/pexels-quang-nguyen-vinh-waterfalls.jpg 600w", "/static/4a9eb7beeb95b7b1ead10bd5652aff71/e5166/pexels-quang-nguyen-vinh-waterfalls.jpg 1200w", "/static/4a9eb7beeb95b7b1ead10bd5652aff71/b17f8/pexels-quang-nguyen-vinh-waterfalls.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Quang Nguyen Vinh / pexels.com`}</em></p>
    <p>{`Before we take an in-depth look at some code examples, here's a brief explanation of RxJS.`}</p>
    <h2>{`What is RxJS and what are Observables?`}</h2>
    <p>{`The name RxJS is an abbreviation for `}<ItalicText mdxType="ItalicText">{`Reactive Extensions Library for JavaScript`}</ItalicText>{`. In the
official `}<a parentName="p" {...{
        "href": "https://rxjs.dev/guide/overview"
      }}>{`RxJS Introduction`}</a>{` it says:`}</p>
    <blockquote>
      <p parentName="blockquote">{`RxJS is a library for composing asynchronous and event-based programs by using `}<BoldText mdxType="BoldText">{`observable sequences`}</BoldText>{`.`}</p>
    </blockquote>
    <p>{`The term “observable sequence” is key here. It represents the idea of an invokable collection of future values. The related
functionality is encapsulated in the `}<InlineCode mdxType="InlineCode">{`Observable`}</InlineCode>{` class.`}</p>
    <p>{`For example, you can create an observable that emits the value of a text input on every value change. Or, you can wrap
the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise"
      }}>{`Promise`}</a>{` of
a `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch"
      }}>{`fetch request`}</a>{` in an observable that emits the
response when the promise completes.`}</p>
    <p>{`Another key concept to keep in mind is the use of a `}<InlineCode mdxType="InlineCode">{`pipe`}</InlineCode>{`. Using an
observable's `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/pipe"
      }}>{`pipe function`}</a>{`, we can execute a series of actions on the observable
and its datastream. If you're new to RxJS, you should learn the basics with the official docs. I can also recommend the
website `}<a parentName="p" {...{
        "href": "https://www.learnrxjs.io/"
      }}>{`Learn RxJS`}</a>{`.`}</p>
    <h2>{`The Challenge`}</h2>
    <p>{`In a current client project, I was faced with the following challenge: The web application should enable users to make
specific data sets available offline. As a first step, I turned the app into
a `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps"
      }}>{`Progressive web app`}</a>{` and created
an `}<a parentName="p" {...{
        "href": "https://angular.io/guide/creating-injectable-service"
      }}>{`injectable service`}</a>{` that handles the data storage
with `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/IndexedDB"
      }}>{`IndexedDB`}</a>{`.`}</p>
    <p>{`Then I was lost for a moment!`}</p>
    <p>{`To be honest: I've never had to retrieve information from different API endpoints where the result of some initial requests
would determine hundreds of follow-up requests. I had to optimize the request sequence for concurrency as well as interdependency.`}</p>
    <p>{`After a lot of online research and some trial and error, I arrived at a satisfying solution. The following source code is an
adapted version of my client project, which I'm happy to share with you.`}</p>
    <h2>{`The Solution`}</h2>
    <p>{`Do you like going to the movies? Imagine a web application that shows the movie program for different cinemas. You can see when
which movie is playing, access information about the actors, watch film clips and browse through screenshots. You can also read
general information about the cinemas (e.g. name, location) and their movie halls (e.g., number of seats).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAAB68VJXmXA/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMTISL/2gAIAQEAAQUC4WbEhmgNK8jothP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAwEBPwGI/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABsQAAMAAgMAAAAAAAAAAAAAAAABESExQWGB/9oACAEBAAY/AsY7KzlelGiXR//EABoQAAIDAQEAAAAAAAAAAAAAAAABESExUUH/2gAIAQEAAT8hmsTrgUSX0SE1eYNAhElrWh//2gAMAwEAAgADAAAAEFDv/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARMVH/2gAIAQMBAT8Qw2Mrt//EABYRAAMAAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxB0Rn//xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMUFR0fH/2gAIAQEAAT8QqirCouvCKw2M0IQLolyB2vJkQ0nd4/Ii90gb7c2eNbX5P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Several bags of popcorn.",
          "title": "Several bags of popcorn.",
          "src": "/static/ab24b446a7a73e5272934938e6cef13c/e5166/pexels-pixabay-popcorn.jpg",
          "srcSet": ["/static/ab24b446a7a73e5272934938e6cef13c/f93b5/pexels-pixabay-popcorn.jpg 300w", "/static/ab24b446a7a73e5272934938e6cef13c/b4294/pexels-pixabay-popcorn.jpg 600w", "/static/ab24b446a7a73e5272934938e6cef13c/e5166/pexels-pixabay-popcorn.jpg 1200w", "/static/ab24b446a7a73e5272934938e6cef13c/b17f8/pexels-pixabay-popcorn.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Pixabay / pexels.com`}</em></p>
    <p>{`To retrieve and store all this data offline, we need to access different endpoints. Some of the requests (e.g., for each movie)
depend on information retrieved in other requests (e.g., for the movie program). This means, we first need to define a logical
order for our download sequence.`}</p>
    <h3>{`Step 1: The Main Download Pipe`}</h3>
    <p>{`In my project, the `}<InlineCode mdxType="InlineCode">{`CinemaDownloadService`}</InlineCode>{` class contains the entire download logic. The service can be
injected by, e.g., an `}<a parentName="p" {...{
        "href": "https://angular.io/api/core/Component"
      }}>{`Angular component`}</a>{`. It provides the public
method `}<InlineCode mdxType="InlineCode">{`downloadCinemaInfo`}</InlineCode>{` that downloads all necessary information to make a cinema and its
movie program available offline:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`public downloadCinemaInfo(cinemaId: number): Observable<CinemaOfflineData> {
  return this.downloadDataForCinemaId(cinemaId).pipe(
    switchMap(this.downloadMovies),
    switchMap(this.downloadActorBiographies),
    switchMap(this.downloadFilmClips),
    switchMap(this.downloadScreenshots),
  );
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The method returns an observable that emits the data collection `}<InlineCode mdxType="InlineCode">{`CinemaOfflineData`}</InlineCode>{` once all individual
downloads have finished.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`export interface CinemaOfflineData {
  generalInfo: CinemaGeneralInfo;
  movieHalls: MovieHall[];
  movieProgram: MovieProgram;
  movies: Movie[];
  actorBios: Actor[];
  filmClips: FilmClip[];
  screenshots: MovieScreenshot[];
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`To collect the data, the method first downloads all information that directly depends on `}<InlineCode mdxType="InlineCode">{`cinemaId`}</InlineCode>{`. We'll
take a closer look at this in a moment. Afterwards, it uses a `}<InlineCode mdxType="InlineCode">{`pipe`}</InlineCode>{` and the `}<InlineCode mdxType="InlineCode">{`switchMap`}</InlineCode>{`
operator to download the remaining data.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/switchMap"
      }}>{`switchMap operator`}</a>{` takes the value emitted by the observable to create a new
observable, which replaces the old one. To put it in simpler terms: Once we're finished with a specific download operation, we start a
new one that incorporates the result of the previous operation.`}</p>
    <h3>{`Step 2: Download Basic Information`}</h3>
    <p>{`As a first step, we want to download the general information about the cinema, its movie halls and the current movie program:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`private downloadDataForCinemaId(cinemaId: number): Observable<CinemaOfflineData> {
  return forkJoin([
    this._request.getCinemaGeneralInfo(cinemaId).pipe(
      retryStrategy()
    ),
    this._request.getMovieHalls(cinemaId).pipe(
      retryStrategy()
    ),
    this._request.getMovieProgram(cinemaId).pipe(
      retryStrategy()
    ),
  ]).pipe(
    map(([generalInfo, movieHalls, movieProgram]: [CinemaGeneralInfo, MovieHall[], MovieProgram]) => {
      const data: CinemaOfflineData = {
        generalInfo, movieHalls, movieProgram,
        movies: [], actorBios: [],
        filmClips: [], screenshots: [],
      };
      return data;
    })
  );
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`The individual backend requests are made through an independent `}<InlineCode mdxType="InlineCode">{`RequestService`}</InlineCode>{`, which we access
through `}<InlineCode mdxType="InlineCode">{`this._request`}</InlineCode>{`. The details of the implementation are irrelevant for this post. All you need to know is:
Each request method returns an observable that emits the response and then completes.`}</p>
    <p>{`We use the `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/forkJoin"
      }}>{`forkJoin operator`}</a>{` to wait for all requests to finish and then create the
first instance of our `}<InlineCode mdxType="InlineCode">{`CinemaOfflineData`}</InlineCode>{` object. The remaining properties, like `}<InlineCode mdxType="InlineCode">{`movies`}</InlineCode>{`,
are initialized as empty arrays. We'll download the corresponding
data in the next steps of our download pipe.`}</p>
    <h3>{`Step 3: Limit Concurrent Requests with `}<InlineCode mdxType="InlineCode">{`mergeMap`}</InlineCode></h3>
    <p>{`After we've successfully downloaded the movie program, we want to retrieve all movies that are listed in the program. Depending on
the size of the cinema and the covered time period, this could mean hundreds of individual requests.`}</p>
    <p>{`The modern `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Glossary/HTTP_2"
      }}>{`HTTP/2 protocol`}</a>{` supports full request multiplexing. But
depending on your backend server and the `}<a parentName="p" {...{
        "href": "https://medium.com/geekculture/how-to-calculate-server-max-requests-per-second-38a39bb96a85"
      }}>{`maximum requests per second`}</a>{`,
it might be a good idea to limit the number of concurrent requests.`}</p>
    <p>{`To achieve this, we use a combination of the following RxJS functions: `}<InlineCode mdxType="InlineCode">{`from`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`mergeMap`}</InlineCode>{`,
and `}<InlineCode mdxType="InlineCode">{`reduce`}</InlineCode>{`. Here's the complete movies download method:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`private downloadMovies = (data: CinemaOfflineData): Observable<CinemaOfflineData> => {
  const movies: Movie[] = [];
  const movieIds = this.getMovieIds(data.movieProgram);
  return from(movieIds).pipe(
    mergeMap(
      id => this._request.getMovieInfo(id).pipe(
        retryStrategy()
      ),
      MAX_CONCURRENT_BACKEND_REQUESTS
    ),
    reduce(
      (accumulator, item) => {
        accumulator.push(item);
        return accumulator;
      },
      movies
    ),
    map(movies => ({ ...data, movies }))
  );
};`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`I know, that's a lot to take in. Let's look at the implementation step by step:`}</p>
    <ol>
      <li parentName="ol">{`We extract the unique `}<InlineCode mdxType="InlineCode">{`movieIds`}</InlineCode>{` from the movie program we've already downloaded. Then we
use `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/from"
        }}>{`from`}</a>{` to turn the array of movie IDs into an observable.`}</li>
      <li parentName="ol">{`Next, we use `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/mergeMap"
        }}>{`mergeMap`}</a>{` to create a request for each movie. We pass the
constant `}<InlineCode mdxType="InlineCode">{`MAX_CONCURRENT_BACKEND_REQUESTS`}</InlineCode>{` as the second parameter to limit the max number of concurrent
requests.`}</li>
      <li parentName="ol">{`Each individual request observable applies the `}<InlineCode mdxType="InlineCode">{`retryStrategy`}</InlineCode>{` operator to repeat the request in
case it should throw an error. This is my own custom operator, which uses the `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/retry"
        }}>{`retry function`}</a>{`.`}</li>
      <li parentName="ol">{`The next element in our observable pipe is `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/reduce"
        }}>{`reduce`}</a>{`. We use this function to
accumulate the results of all backend requests into a single `}<InlineCode mdxType="InlineCode">{`movies`}</InlineCode>{` array.`}</li>
      <li parentName="ol">{`Last, we integrate the movies data into a new instance of our `}<InlineCode mdxType="InlineCode">{`CinemaOfflineData`}</InlineCode>{` object, using
the `}<a parentName="li" {...{
          "href": "https://rxjs.dev/api/index/function/map"
        }}>{`map operator`}</a>{`.`}</li>
    </ol>
    <p>{`Now the main download pipe can move on the the next step: Downloading the biographies for all actors. We extract the
unique `}<InlineCode mdxType="InlineCode">{`actorIds`}</InlineCode>{` from the movies array and apply the same download logic as before:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`private downloadActorBiographies = (data: CinemaOfflineData): Observable<CinemaOfflineData> => {
  const actorBios: Actor[] = [];
  const actorIds = this.getActorIds(data.movies);
  return from(actorIds).pipe(
    mergeMap(
      id => this._request.getActorBiography(id).pipe(
        retryStrategy()
      ),
      MAX_CONCURRENT_BACKEND_REQUESTS
    ),
    reduce(
      (accumulator, item) => {
        accumulator.push(item);
        return accumulator;
      },
      actorBios
    ),
    map(actorBios => ({ ...data, actorBios }))
  );
};`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`We repeat the same steps for the film clips and the screenshots.`}</p>
    <h3>{`Step 4: Subscribe to the Download Observable`}</h3>
    <p>{`To execute the whole download logic, you need to call the public method `}<InlineCode mdxType="InlineCode">{`downloadCinemaInfo`}</InlineCode>{` and
subscribe to the observable it returns:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`this._cinemaDownloadService.downloadCinemaInfo(cinemaId)
  .subscribe((cinemaData: CinemaOfflineData) => {
    /* Store data offline, e.g., with IndexedDB */
  });`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`That's it! You've successfully created an elegant download logic that is optimized for concurrency and interdependent data.`}</p>
    <p>{`Of course, you'll also want to cover error handling using `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/function/catchError"
      }}>{`catchError`}</a>{`. You might
also want to visualize the download process using a `}<a parentName="p" {...{
        "href": "https://rxjs.dev/api/index/class/Subject"
      }}>{`Subject`}</a>{` to communicate the
current status.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`As we've seen, RxJS offers a set of powerful tools like `}<InlineCode mdxType="InlineCode">{`mergeMap`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`reduce`}</InlineCode>{`.
They help us to create efficient and robust solutions.`}</p>
    <p>{`You can define a main download pipe that provides a quick overview of the individual steps. Then you place the specific
download logic into isolated private functions. This helps to keep your code clean and maintainable.`}</p>
    <h2>{`Useful Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://rxjs.dev/"
        }}>{`RxJS (Reactive Extensions Library for JavaScript)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.learnrxjs.io/"
        }}>{`Learn RxJS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://netbasal.com/creating-custom-operators-in-rxjs-32f052d69457"
        }}>{`Creating Custom Operators in RxJS`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      